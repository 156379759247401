import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import SubjectService from "../../SubjectService";
import {Checkbox, Dropdown} from "semantic-ui-react";
import Pagination from "semantic-ui-react/dist/commonjs/addons/Pagination";
import {AgGridReact} from "ag-grid-react";
import {useTranslation} from "react-i18next";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';


const SUBJECT_PAGE_COUNT = 25

function SubjectSelectModalInsert({allGroups, groups, groupLabels, subjects, setSubjects}){
  const {t} = useTranslation();

  const [selectedGroup, setSelectedGroup] = useState(groups.length ? groups[0] : allGroups[0])
  const [subjectCount, setSubjectCount] = useState(null);
  const previousGroupRef = useRef(selectedGroup)
  const fetchSubjectCount = useCallback (async (group) => {
    await setSubjectCount(null)
    const count = await SubjectService.getSubjectsCount(group)
    setSubjectCount(count)
  }, []);
  useEffect(()=>{
    fetchSubjectCount(selectedGroup)
  }, [fetchSubjectCount, selectedGroup])
  const hasSubjectCountLoaded = subjectCount !== null;

  // fetching subjects
  const [subjectPage, setSubjectPage] = useState(null)
  const [pageIndex, setPageIndex] = useState(0) // zero indexed, but Pagination display will be 1 indexed
  const fetchSubjectPage = useCallback(async (pageIndex) => {
    let controlledPageIndex = pageIndex;
    if(!selectedGroup) return null;

    // if we are looking at a new group, then start at first page
    if(selectedGroup !== previousGroupRef.current){
      controlledPageIndex = 0;
      setPageIndex(0)
      previousGroupRef.current = selectedGroup
    }

    await setSubjectPage(null)
    const subjects = await SubjectService.listSubjects(
        selectedGroup,
        controlledPageIndex,
        null,
        null,
        null,
        null,
        null,
        null,
        false);
    setSubjectPage(subjects)
  }, [selectedGroup]);
  useEffect(()=>{
    fetchSubjectPage(pageIndex)
  }, [pageIndex, fetchSubjectPage]);

  const handleSubjectSelect = useCallback((code, isAdding) => {
    if(isAdding){
      const subject = subjectPage?.pageSubjects?.find(s => s.subjectCode === code)
      setSubjects(prev=>prev.concat([subject.subjectCode]))
    }else{
      setSubjects(prev=>prev.filter(s=>s !== code))
    }
  }, [setSubjects, subjectPage])
  const SelectRowComponent = useMemo(() => {
    return function SelectRow(props) {
      const handleChange = useCallback((e, data)=>{handleSubjectSelect(props.data.code, data.checked)}, [props.data.code])
      return <Checkbox checked={props.data.isSelected} onChange={handleChange}/>
    }
  },[handleSubjectSelect])
  const subjectHeaders = [
    {field: "isSelected", cellRenderer:SelectRowComponent, headerName: t("VISIT_COMPLIANCE_HEADER_SUBJECT_SELECTED", "is Selected?") },
    {field: "code", headerName: t("VISIT_COMPLIANCE_HEADER_SUBJECT_CODE", "Subject Code")},
  ]
  const subjectPageForDisplay = useMemo(()=>{
    if(!Array.isArray(subjectPage?.pageSubjects)) return []
    return subjectPage.pageSubjects.map(s=>{
      const isSelected = subjects.find(_s=> s.subjectCode === _s) !== undefined;
      return {
        isSelected,
        code: s.subjectCode,
      }
    })
  }, [subjectPage, subjects])

  const selectedSubjectDataForDisplay = useMemo(()=>{
    if(!Array.isArray(subjects)) return []
    return subjects.map(s=>({
      isSelected: true,
      code: s,
    }))
  }, [subjects])


  return <div>
    <div>
      <div style={styles.container}>
        <label style={styles.label}  htmlFor={'visit-compliance-subject-select-groups'}>
          {t('VISIT_COMPLIANCE_SUBJECT_GROUP_FILTER_LABEL', 'Select Group')}
        </label>
        <Dropdown
            id={'visit-compliance-subject-select-groups'}
            placeholder={t("VISIT_COMPLIANCE_SELECT_PLACEHOLDER", "Select...")}
            selection
            options={groups.length > 0 ? groups.map(g=>({text:groupLabels[g], value:g})) : allGroups.map(g=>({text:groupLabels[g], value:g}))}
            value={selectedGroup}
            onChange={(e, data) => setSelectedGroup(data.value)}
        />
      </div>
      <div style={styles.headers}>
        <div style={{width: '50%'}}>
          {hasSubjectCountLoaded && <Pagination
              totalPages={Math.ceil(subjectCount/SUBJECT_PAGE_COUNT)}
              activePage={pageIndex + 1}
              onPageChange={(i, d) => { setPageIndex(d.activePage - 1) }}
          />}
        </div>
        <div style={{width: '50%'}}>
          <h3>{t("VISIT_COMPLIANCE_SELECTED_SUBJECT_LIST", "Selected Subjects")}</h3>
        </div>
      </div>
      <div style={styles.tables}>
        <div className='ag-theme-balham' style={{ width: '50%', height: 585 }}>
          <AgGridReact
              columnDefs={subjectHeaders}
              rowData={subjectPageForDisplay}
          ></AgGridReact>

        </div>
        <div className='ag-theme-balham' style={{ width: '50%', height: 585 }}>
          <AgGridReact
              columnDefs={subjectHeaders}
              rowData={selectedSubjectDataForDisplay}
          ></AgGridReact>
        </div>
      </div>

    </div>
  </div>
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '16px',
  },
  label: {
    fontWeight: 'bold',
    paddingRight: '8px'
  },
  tables: {
    display: 'flex',
  },
  headers: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  }
}

export  default SubjectSelectModalInsert;