import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import { compose } from 'redux';
import withPermissionWrapper from "../security/withPermissionWrapper";
import StaffPermissionService from "../services/StaffPermissionService";
import {AgGridReact} from "ag-grid-react";
import VisitComplianceReportFilters from "./VisitComplianceReport/VisitComplianceReportFilters";
import { saveAs } from 'file-saver'


import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import VisitComplianceService from "./VisitComplianceService";
import Pagination from "semantic-ui-react/dist/commonjs/addons/Pagination";
import {Button, Loader} from "semantic-ui-react";
import Page from "../components/page/Page";
import {DateTime} from 'luxon';
import convertString from "../utility/convertString";

function VisitComplianceReportPage(){
  const {t} = useTranslation()

  // define filters
  const [countriesFilter, setCountriesFilter] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);
  const [subjectFilter, setSubjectFilter] = useState([]);

  // countries for filtering
  const [distinctCountries, setDistinctCountries] = useState(null);
  const fetchCountries = useCallback(async () => {
    const countries = await VisitComplianceService.getDictionary(
        VisitComplianceService.DICTIONARY_TYPES.COUNTRY
    )
    setDistinctCountries(countries)
  },[setDistinctCountries])
  const haveDistinctCountriesLoaded = Array.isArray(distinctCountries) && distinctCountries.length !== 0;

  // groups for filtering
  const [groups, setGroups] = useState(null);
  const fetchGroups = useCallback(async (countriesFilter)=>{
    const groups = await VisitComplianceService.getDictionary(
        VisitComplianceService.DICTIONARY_TYPES.GROUP,
        countriesFilter
    )
    setGroups(groups)
  },[])
  const haveGroupsLoaded = Array.isArray(groups)


  useEffect(()=>{
    fetchCountries()
    fetchGroups(countriesFilter)
  }, [fetchCountries, fetchGroups, countriesFilter, groupFilter, subjectFilter])
  const hasLoadedFilterData = useMemo(
      ()=>haveDistinctCountriesLoaded && haveGroupsLoaded,
      [haveDistinctCountriesLoaded,haveGroupsLoaded]
  )

  // fetch report data
  const [reportData, setReportData] = useState(null)
  const [averageReportCompliance, setAverageReportCompliance] = useState(null)
  const [visitReportPage, setVisitReportPage] = useState(0)
  const isDate = (key)=>["visitStartdate", "visitEnddate"].includes(key)
  const isCountry = (key)=>["country"].includes(key)
  const fetchVisitReport = useCallback (async (page, countriesFilter, groupFilter, subjectFilter) => {
    const [report, compliance] = await Promise.all([
        VisitComplianceService.getVisitCompliance(page, countriesFilter, groupFilter, subjectFilter),
        VisitComplianceService.getAggregateCompliance(countriesFilter, groupFilter, subjectFilter)
    ]);

    // filters have change and selected page is no longer valid
    if (report.visitCompliance.totalPages - 1 < page) {
      setVisitReportPage(report.visitCompliance.totalPages - 1)
      return
    }

    report.visitCompliance.content = report.visitCompliance.content.map(r => {
      const formattedData = {};
      Object.keys(r).forEach(key=> {
        if(isDate(key)) {
          formattedData[key] = DateTime.fromISO(r[key]).toFormat("dd-LLL-yyyy")
        } else if(isCountry(key)) {
          formattedData[key] = t(`VISIT_COMPLIANCE_COUNTRY_${r[key].toUpperCase()}`)
        } else {
          formattedData[key] = r[key]
        }
      })
      return formattedData;
    })

    setReportData(report)
    setAverageReportCompliance(compliance)
  }, [t])
  useEffect(()=>{
    fetchVisitReport(visitReportPage, countriesFilter, groupFilter, subjectFilter);
  },[visitReportPage, countriesFilter, groupFilter, subjectFilter, fetchVisitReport])
  const hasReportDataLoaded = Array.isArray(reportData?.visitCompliance?.content);


  const handleExport = useCallback(async () => {
    const fileData = await VisitComplianceService.getCSVExport(countriesFilter, groupFilter, subjectFilter)
    const fileDate = DateTime.now().toFormat("dd-LLL-yyyy");
    let filename = `visit-compliance-export-${fileDate}-filtered.zip`
    saveAs(fileData, filename);
  }, [countriesFilter, groupFilter, subjectFilter])

  const headerValueFormatter = (key) => `VISIT_COMPLIANCE_HEADER_${key.toUpperCase()}`


  return <Page header={t("VISIT_COMPLIANCE_REPORT_HEADER", "Visit Compliance Report")} >
    {hasLoadedFilterData && <VisitComplianceReportFilters
        countries={countriesFilter}
        setCountries={setCountriesFilter}
        allCountries={distinctCountries}
        groups={groupFilter}
        setGroups={setGroupFilter}
        allGroups={groups}
        subjects={subjectFilter}
        setSubjects={setSubjectFilter}
    />}
    {!hasReportDataLoaded && <Loader active /> }
    {hasReportDataLoaded && <>
      <div style={styles.container}>
        <Pagination
            style={{alignSelf: 'flex-end'}}
            totalPages={reportData?.visitCompliance?.totalPages}
            activePage={visitReportPage + 1}
            onPageChange={(e, d) => {
              setVisitReportPage(d.activePage - 1)
            }}
        />
        <Button onClick={handleExport} size={"small"}
                primary>{t("VISIT_COMPLIANCE_REPORT_CSV_EXPORT", "Export")}</Button>
        <p style={styles.average}>{t("VISIT_COMPLIANCE_REPORT_AVERAGE", "Overall Compliance:") + ` ${averageReportCompliance?.complianceCalc}%`}</p>
      </div>
      <div className='ag-theme-balham' style={{width: '100%', height: 585}}>
        <AgGridReact
            columnDefs={reportData?.columns.map(c => ({field: c, headerName: t(headerValueFormatter(c), convertString.fromCamelCase.toCapitalised(c))}))}
            rowData={reportData?.visitCompliance?.content}
        />
      </div>
    </>}
  </Page>
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  average:{
    fontSize: '1.25rem',
    fontWeight: 'bold'
  }
}


const withEnhancements = (options) => compose(
    withPermissionWrapper(options),
    withTranslation()
);
export default withEnhancements({
  permissionFunctionDelegate: StaffPermissionService.Reports.canAccessVisitComplianceReport
})(VisitComplianceReportPage);